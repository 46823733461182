import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import style from './IndexList.module.scss';
import Oval1 from '../../../../public/statics/svgs/oval1.svg';
import Oval1Filled from '../../../../public/statics/svgs/oval1-filled.svg';
import Oval2 from '../../../../public/statics/svgs/oval2.svg';
import Oval2Filled from '../../../../public/statics/svgs/oval2-filled.svg';
import Oval3 from '../../../../public/statics/svgs/oval3.svg';
import Oval3Filled from '../../../../public/statics/svgs/oval3-filled.svg';
import Oval4 from '../../../../public/statics/svgs/oval4.svg';
import Oval4Filled from '../../../../public/statics/svgs/oval4-filled.svg';

const ovals = [<Oval1 key={1} />, <Oval2 key={2} />, <Oval3 key={3} />, <Oval4 key={4} />];
const filledOvals = [
  <Oval1Filled key={1} />,
  <Oval2Filled key={2} />,
  <Oval3Filled key={3} />,
  <Oval4Filled key={4} />
];

interface Element {
  title: React.ReactNode;
  oval: JSX.Element;
  filledOval: JSX.Element;
  textAdjustmentLeft: number;
  textAdjustmentTop: number;
  textAdjustmentBottom: number;
  textAdjustmentRight: number;
}

const IndexListElement: React.FC<{ elements: Element[] }> = ({ elements }) => {
  return (
    <div className="align-items-center d-flex flex-column my-5">
      <div className="d-flex h-100 w-100 flex-column flex-lg-row justify-content-around align-items-lg-center">
        {elements.map((genericElement) => {
          return (
            <div
              key={`${genericElement.title}`}
              className={classNames('position-relative flex-grow-1', style.filledOval)}
            >
              {genericElement.filledOval}
              <div
                className={style.elementText}
                style={{
                  top: `${genericElement.textAdjustmentTop + 25}%`,
                  left: `${genericElement.textAdjustmentLeft + 25}%`,
                  bottom: `${genericElement.textAdjustmentBottom + 25}%`,
                  right: `${genericElement.textAdjustmentRight + 25}%`
                }}
              >
                <div>{genericElement.title}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const IndexList: React.FC = () => {
  const intl = useIntl();
  const elements = useMemo(
    () => [
      {
        title: intl.formatMessage(
          {
            id: 'web.home.list.real-game-show',
            defaultMessage: 'Feel like a <b>real game show</b> contestant'
          },
          { b: (children: any) => <span>{children}</span> }
        ),
        oval: ovals[0],
        filledOval: filledOvals[0],
        textAdjustmentLeft: 5,
        textAdjustmentTop: -3,
        textAdjustmentBottom: 10,
        textAdjustmentRight: 0
      },
      {
        title: intl.formatMessage(
          {
            id: 'web.home.list.fun-friendly',
            defaultMessage: 'Insanely <b>fun</b> and <b>friendly</b> competition'
          },
          { b: (children: any) => <span>{children}</span> }
        ),
        oval: ovals[1],
        filledOval: filledOvals[1],
        textAdjustmentLeft: 0,
        textAdjustmentTop: 10,
        textAdjustmentBottom: 0,
        textAdjustmentRight: 0
      },
      {
        title: intl.formatMessage(
          {
            id: 'web.home.list.event-to-be-remembered',
            defaultMessage: 'Make your event one to be <b>remembered</b>'
          },
          { b: (children: any) => <span>{children}</span> }
        ),
        oval: ovals[2],
        filledOval: filledOvals[2],
        textAdjustmentLeft: -7,
        textAdjustmentTop: -5,
        textAdjustmentBottom: 10,
        textAdjustmentRight: 5
      },
      {
        title: intl.formatMessage({
          id: 'web.home.list.great-for-all-ages',
          defaultMessage: 'Great for all ages!'
        }),
        oval: ovals[3],
        filledOval: filledOvals[3],
        textAdjustmentLeft: -5,
        textAdjustmentTop: 8,
        textAdjustmentBottom: 8,
        textAdjustmentRight: 0
      }
    ],
    [intl]
  );
  return (
    <>
      <IndexListElement elements={elements} />
    </>
  );
};
